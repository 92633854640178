'use strict';

(function() {
  function WeatherResource($resource) {
    return $resource('api/v1/weather/:id/:controller/:page/:limit/:sortBy/:order', {
      id: '@_id'
    }, {
      getForTurbineFindings: {
        method: 'POST',
        // id: 'get-for-blade-findings'
      }
    });
  }

  angular.module('windmanagerApp')
    .factory('Weather', WeatherResource);

})();
